<template>
  <v-row class="app-receipt-preview">
    <v-col
      cols="12"
      md="9"
    >
      <v-card class="clerical-receipt">
        <!-- Header -->
        <v-card-text class="pa-8">
          <div class="receipt-header d-flex flex-wrap justify-space-between">
            <!-- Left Content -->
            <div class="text-left">
              <div class="d-flex align-center mb-6">
                <v-img
                  :src="organization.logo_url || require('@/assets/images/logo.png')"
                  max-height="30px"
                  max-width="30px"
                  alt="logo"
                  contain
                  class="me-3"
                ></v-img>
                <span class="text--primary font-weight-bold text-xl">
                  {{ organization.display_name }}
                </span>
              </div>
              <span class="d-block">{{ organization.name }} ({{ organization.roc_number }})</span>
              <span class="d-block">{{ organization.address }}</span>
              <span class="d-block">Tel: {{ organization.contact }}</span>
              <span class="d-block">{{ organization.website }}</span>
            </div>
            <!-- Right Content -->
            <div class="text-right mt-5 mt-sm-0">
              <div class="mb-4 d-flex align-center justify-end">
                <span class="me-2">Date Issued: </span>
                <v-menu
                  v-model="isIssueDateMenuOpen"
                  :close-on-content-click="false"
                  :nudge-left="$vuetify.breakpoint.smAndDown ? 95 : 0"
                  transition="scale-transition"
                  offset-y
                  eager
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="receiptDateFormatted"
                      class="header-inputs flex-grow-0"
                      readonly
                      outlined
                      dense
                      hide-details="auto"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="receipt.receipt_date"
                    color="primary"
                    :first-day-of-week="1"
                    @input="isIssueDateMenuOpen = false"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="mb-2 d-flex align-center justify-end">
                <span class="me-2">Payment Date: </span>
                <v-menu
                  v-model="isPaymentDateMenuOpen"
                  :close-on-content-click="false"
                  :nudge-left="$vuetify.breakpoint.smAndDown ? 95 : 0"
                  transition="scale-transition"
                  offset-y
                  eager
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="paymentDateFormatted"
                      class="header-inputs flex-grow-0"
                      readonly
                      outlined
                      dense
                      hide-details="auto"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="receipt.payment_date"
                    color="primary"
                    :first-day-of-week="1"
                    @input="isPaymentDateMenuOpen = false"
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <!-- Payment Details -->
        <v-card-text class="px-8">
          <div class="d-sm-flex my-3 justify-space-between">
            <div>
              <div class="d-flex align-center">
                <p class="font-weight-semibold payment-details-header mb-0 mr-4 mr-sm-2 text-no-wrap">
                  Receipt To:
                </p>
                <v-autocomplete
                  v-if="!receipt.invoice_meta && !$route.params.invoice_id"
                  ref="customerList"
                  v-model="receipt.customer_id"
                  :items="customers"
                  cache-items
                  item-text="name"
                  item-value="id"
                  single-line
                  outlined
                  dense
                  hide-details
                  return-object
                  placeholder="Select Customer"
                  class="ml-1 mr-0 mr-sm-5"
                  @change="undoInvoice(); receipt.customer = $event; receipt.customer_id = $event.id; fetchInvoiceMetas()"
                >
                  <template #append-item>
                    <div class="pa-0 mt-2 text-center append-select">
                      <v-btn
                        block
                        depressed
                        color="primary"
                        class="rounded-0"
                        @click="isAddNewCustomerDialogOpen = true"
                      >
                        Add new customer
                      </v-btn>
                    </div>
                  </template>
                </v-autocomplete>
              </div>
              <div
                v-if="receipt.customer"
                class="my-3"
              >
                <p class="mb-1">
                  {{ receipt.customer.name }}
                </p>
                <p class="mb-1">
                  {{ receipt.customer.company }}
                </p>
                <p
                  v-if="receipt.customer.address"
                  class="mb-1"
                >
                  {{ receipt.customer.address }}
                </p>
                <p class="mb-1">
                  {{ receipt.customer.contact_number }}
                </p>
                <p class="mb-0">
                  {{ receipt.customer.email }}
                </p>
              </div>
            </div>
            <div>
              <div
                v-if="!$route.params.id && !$route.params.invoice_id"
                class="d-flex my-4 mt-sm-0"
              >
                <p class="font-weight-semibold mt-2 mb-0 ml-0 ml-sm-12 mr-5 text-no-wrap">
                  Invoice No:
                </p>
                <v-autocomplete
                  v-if="!receipt.invoice_meta && !$route.params.invoice_id"
                  ref="invoiceMetaList"
                  v-model="receipt.invoice_id"
                  :loading="searchInvoiceLoading"
                  :items="invoiceMetas"
                  :search-input.sync="searchInvoiceText"
                  cache-items
                  item-value="id"
                  item-text="description"
                  single-line
                  outlined
                  dense
                  hide-details
                  placeholder="Type To Search"
                  class="payment-inputs flex-grow-0"
                  @change="selectInvoice($event)"
                >
                  <template v-slot:append>
                    <v-progress-circular
                      v-if="fillInvoiceLoading"
                      color="primary"
                      indeterminate
                      size="25"
                    ></v-progress-circular>
                    <v-icon
                      v-else-if="!!receipt.invoice_id"
                      size="25"
                      @click="undoInvoice"
                    >
                      {{ icons.mdiClose }}
                    </v-icon>
                  </template>
                </v-autocomplete>
                <router-link
                  v-if="$route.params.invoice_id"
                  class="font-weight-medium text-decoration-none"
                  :to="{ name: 'invoice-view', params: { id: route.params.invoice_id } }"
                >
                  {{ receipt.invoice_number }}
                </router-link>
              </div>
              <div class="d-flex">
                <p
                  class="font-weight-semibold mt-2 mb-0 ml-0 ml-sm-1 mr-0 mr-sm-5"
                  :class="$vuetify.breakpoint.smAndDown ? 'text-wrap' : 'text-no-wrap'"
                >
                  Payment Method:
                </p>
                <v-autocomplete
                  ref="paymentMethodList"
                  v-model="receipt.payment_method_id"
                  :items="paymentMethods"
                  cache-items
                  item-text="name"
                  item-value="id"
                  single-line
                  outlined
                  dense
                  hide-details
                  return-object
                  placeholder="Select Method"
                  class="payment-inputs my-auto flex-grow-0"
                  @change="receipt.payment_method = $event; receipt.payment_method_id = $event.id"
                >
                  <template #append-item>
                    <div class="pa-0 mt-2 text-center append-select">
                      <v-btn
                        block
                        depressed
                        color="primary"
                        class="rounded-0"
                        @click="addPaymentMethodDialog = true"
                      >
                        Add new payment method
                      </v-btn>
                    </div>
                  </template>
                </v-autocomplete>
              </div>

              <div v-if="receipt.invoice_meta || $route.params.invoice_id">
                <div class="mt-3 mb-2 d-flex align-center justify-start justify-sm-end">
                  <span class="me-2">Invoice No: </span>
                  <router-link
                    v-if="receipt.invoice_meta"
                    class="font-weight-medium text-decoration-none ml-6 ml-sm-0"
                    :to="{ name: 'invoice-view', params: { id: receipt.invoice_meta.id } }"
                  >
                    {{ receipt.invoice_meta.invoice_number }}
                  </router-link>
                  <router-link
                    v-else
                    class="font-weight-medium text-decoration-none"
                    :to="{ name: 'invoice-view', params: { id: $route.params.invoice_id } }"
                  >
                    {{ receipt.invoice_number }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <div v-if="receipt.invoice_id || $route.params.id">
          <!-- Table -->
          <v-simple-table class="purchased-items-table d-none d-sm-block">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>
                    ITEM
                  </th>
                  <th>
                    UNIT PRICE
                  </th>
                  <th>
                    QUANTITY
                  </th>
                  <th>
                    DISCOUNT
                  </th>
                  <th>
                    AMOUNT
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="line_item in receipt.line_items_attributes"
                  :key="line_item.id"
                >
                  <td
                    v-if="line_item.item"
                    class="text-no-wrap py-2"
                  >
                    {{ line_item.item.name }}<br>
                    <small class="text-wrap">{{ line_item.item.description }}</small>
                  </td>
                  <td class="text-no-wrap">
                    {{ line_item.price.toFixed(2) }}
                  </td>
                  <td>
                    {{ line_item.quantity }}
                  </td>
                  <td>
                    {{ line_item.discount.toFixed(2) }}
                  </td>
                  <td>
                    {{ line_item.amount.toFixed(2) }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <!-- table view for mobile -->
          <v-data-table
            :headers="tableSmHeaders"
            :items="receipt.line_items_attributes"
            :expanded.sync="expanded"
            single-expand
            show-expand
            mobile-breakpoint="0"
            hide-default-footer
            class="fixed-action d-block d-sm-none"
          >
            <!-- Amount -->
            <template #[`item.amount`]="{item}">
              <div>${{ item.amount.toFixed(2) }}</div>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td
                class="table-expand"
                :colspan="headers.length"
              >
                <p class="mt-2">
                  Unit Price: ${{ item.price.toFixed(2) }}
                </p>
                <p>Quantity: {{ item.quantity }}</p>
                <p>Discount: ${{ item.discount.toFixed(2) }}</p>
                <p>Description:</p>
                <p
                  v-if="item.item"
                  class="text-wrap"
                  v-html="item.description"
                ></p>
              </td>
            </template>
          </v-data-table>
        </div>

        <!-- Save Customer Dialog -->
        <v-dialog
          v-model="isAddNewCustomerDialogOpen"
          width="500"
          persistent
        >
          <v-card>
            <v-card-title class="d-flex align-center mv-4 mb-4">
              New Customer
              <v-spacer></v-spacer>
              <v-btn
                icon
                small
                @click="isAddNewCustomerDialogOpen = false; saveCustomerForm.reset()"
              >
                <v-icon size="22">
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text>
              <v-form
                ref="saveCustomerForm"
                v-model="saveCustomerValid"
              >
                <v-text-field
                  v-model="newCustomer.name"
                  outlined
                  dense
                  label="Name"
                  :rules="[validators.required]"
                  hide-details="auto"
                  class="mb-6"
                ></v-text-field>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="newCustomer.company"
                      outlined
                      dense
                      label="Company"
                      :rules="[validators.required]"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-select
                      v-model="newCustomer.gender"
                      :items="['Male', 'Female']"
                      outlined
                      dense
                      label="Gender"
                      :rules="[validators.required]"
                      hide-details="auto"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="newCustomer.email"
                      outlined
                      dense
                      label="Email"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="newCustomer.contact_number"
                      outlined
                      dense
                      label="Number"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-textarea
                  v-model="newCustomer.note"
                  outlined
                  rows="3"
                  label="Notes"
                ></v-textarea>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="primary"
                block
                :loading="saveCustomerLoading"
                :disabled="saveCustomerLoading || !saveCustomerValid"
                @click="saveCustomer"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Add Payment Method Dialog -->
        <v-dialog
          v-model="addPaymentMethodDialog"
          width="500"
          persistent
        >
          <v-card>
            <v-card-title class="d-flex align-center mv-4 mb-4">
              New Payment Method
              <v-spacer></v-spacer>
              <v-btn
                icon
                small
                @click="addPaymentMethodDialog = false; savePaymentMethodForm.reset()"
              >
                <v-icon size="22">
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text>
              <v-form
                ref="savePaymentMethodForm"
                v-model="savePaymentMethodValid"
              >
                <v-text-field
                  v-model="newPaymentMethod.name"
                  outlined
                  dense
                  label="Name"
                  :rules="[validators.required]"
                  hide-details="auto"
                  class="mb-6"
                ></v-text-field>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="primary"
                block
                :loading="savePaymentMethodLoading"
                :disabled="savePaymentMethodLoading || !savePaymentMethodValid"
                @click="savePaymentMethod"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-divider></v-divider>

        <!-- Total -->
        <v-card-text class="pa-8">
          <div class="d-flex justify-space-between flex-wrap flex-column flex-sm-row">
            <v-spacer></v-spacer>
            <div>
              <table class="w-full">
                <tr>
                  <td class="pe-16">
                    Subtotal:
                  </td>
                  <th class="text-right">
                    ${{ parseFloat(receipt.line_items_attributes.reduce((sum, item) => sum + (item.price * item.quantity), 0)).toFixed(2) }}
                  </th>
                </tr>
                <tr>
                  <td class="pe-16">
                    Total Discount:
                  </td>
                  <th class="text-right">
                    ${{ parseFloat(receipt.line_items_attributes.reduce((sum, item) => sum + item.discount, 0)).toFixed(2) }}
                  </th>
                </tr>
              </table>
              <v-divider class="my-3"></v-divider>
              <table class="w-full">
                <tr>
                  <td class="pe-16">
                    Total:
                  </td>
                  <th class="text-right">
                    ${{ parseFloat(receipt.line_items_attributes.reduce((sum, item) => sum + (item.price * item.quantity), 0) - receipt.line_items_attributes.reduce((sum, item) => sum + item.discount, 0)).toFixed(2) }}
                  </th>
                </tr>
                <tr v-if="receipt.amount_due">
                  <td class="pe-16">
                    Amount Due:
                  </td>
                  <th class="text-right">
                    ${{ receipt.amount_due.toFixed(2) }}
                  </th>
                </tr>
                <tr v-if="receipt.invoice_meta">
                  <td class="pe-16">
                    Amount Due:
                  </td>
                  <th class="text-right">
                    ${{ receipt.invoice_meta.amount_due.toFixed(2) }}
                  </th>
                </tr>
              </table>
              <v-divider class="my-4"></v-divider>
              <table class="w-full">
                <tr>
                  <td class="d-flex">
                    <span>Payment Amount:</span>
                    <v-spacer></v-spacer>
                    <v-chip
                      label
                      small
                      color="primary"
                      class="v-chip-light-bg font-weight-bold primary--text"
                      @click="$refs.paymentAmountInput.$emit('max-payment', receipt.amount_due.toFixed(2))"
                    >
                      MAX
                    </v-chip>
                  </td>
                </tr>
                <tr>
                  <th>
                    <v-text-field
                      ref="paymentAmountInput"
                      v-model="receipt.payment_amount"
                      outlined
                      dense
                      min="0"
                      type="number"
                      prefix="BND$"
                      hide-details="auto"
                      oninput="if (this.value < 0) this.value = 0;"
                      class="pt-2"
                      @max-payment="receipt.payment_amount = $event; $forceUpdate()"
                    ></v-text-field>
                  </th>
                </tr>
              </table>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="px-8 py-6">
          <div class="mb-0">
            <p class="font-weight-semibold mb-3">
              Note:
            </p>
            <vue-editor
              v-model="receipt.note"
              placeholder="Will be printed on the receipt"
              :editor-toolbar="customToolBar"
              :class="{ 'dark-mode-toolbar': $vuetify.theme.dark }"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col
      cols="12"
      md="3"
    >
      <v-card class="mb-6">
        <v-card-text>
          <v-btn
            color="primary"
            block
            :loading="saveFormLoading"
            :disabled="saveFormLoading"
            @click="saveReceipt"
          >
            Save
          </v-btn>
        </v-card-text>
      </v-card>
      <v-alert
        v-for="(error, index) in saveFormErrors"
        :key="index"
        border="left"
        color="error"
        dark
        text
      >
        {{ error }}
      </v-alert>

      <ActivityLog :activities="activities" />
    </v-col>
  </v-row>
</template>

<script>
import {
  ref,
  inject,
  onMounted,
  computed,
  watch,
} from '@vue/composition-api'
import { VueEditor } from 'vue2-editor'
import { mdiClose, mdiMenuDown } from '@mdi/js'
import { useRouter } from '@core/utils'
import { required } from '@core/utils/validation'
import ActivityLog from '@/components/activity/ActivityLog'

export default {
  components: { VueEditor, ActivityLog },
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const utilsService = inject('utilsService')
    const { route, router } = useRouter()
    const receiptId = route.value.params.id
    const organizationData = computed(() => store.state.organization)

    // Properties
    const [dayNow, monthNow, yearNow] = new Date().toLocaleDateString('en-SG').split('/')
    const receipt = ref({
      receipt_date: `${yearNow}-${monthNow}-${dayNow}`,
      payment_date: `${yearNow}-${monthNow}-${dayNow}`,
      line_items_attributes: [{
        item_id: null,
        category_id: null,
        name: null,
        price: 0.00,
        quantity: 1,
        discount: 0.00,
        amount: 0.00,
      }],
      payment_amount: null,
      amount_due: 0.00,
    })
    const organization = ref({ ...organizationData.value })
    const isIssueDateMenuOpen = ref(false)
    const isPaymentDateMenuOpen = ref(false)

    const searchInvoiceLoading = ref(false)
    const searchInvoiceText = ref('')
    const invoiceMetas = ref([])
    const invoiceMetaList = ref(null)
    const fillInvoiceLoading = ref(false)

    const customers = ref([])
    const newCustomer = ref({})
    const saveCustomerForm = ref(null)
    const saveCustomerValid = ref(false)
    const saveCustomerLoading = ref(false)
    const isAddNewCustomerDialogOpen = ref(false)

    const paymentMethods = ref([])
    const newPaymentMethod = ref({})
    const addPaymentMethodDialog = ref(false)
    const savePaymentMethodForm = ref(null)
    const savePaymentMethodValid = ref(false)
    const savePaymentMethodLoading = ref(false)

    const activities = ref([])

    const saveFormLoading = ref(false)
    const saveFormErrors = ref([])
    const customToolBar = [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' },
      ],
    ]

    // Table Handlers
    const expanded = ref([])
    const tableSmHeaders = [
      { text: 'ITEM', value: 'item.name' },
      { text: 'AMOUNT', value: 'amount', sortable: false },
      { text: '', value: 'data-table-expand' },
    ]

    // Computed
    const receiptDateFormatted = computed(() => utilsService.formatDate(receipt.value.receipt_date))
    const paymentDateFormatted = computed(() => utilsService.formatDate(receipt.value.payment_date))

    // Methods
    const fetchCustomers = () => {
      store
        .dispatch('clericalSettingsStore/fetchCustomers', { no_pagination: true })
        .then(response => {
          customers.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching customers. Please refresh!')
        })
    }
    const fetchPaymentMethods = () => {
      store
        .dispatch('knowledgeBaseStore/fetchPaymentMethods', { no_pagination: true })
        .then(response => {
          paymentMethods.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching payment methods. Please refresh!')
        })
    }
    const fetchReceipt = id => {
      store
        .dispatch('receiptStore/fetchReceipt', { id, as_form: true })
        .then(response => {
          receipt.value = response.data.data
          receipt.value.payment_method_id = paymentMethods.value.find(pm => pm.name === receipt.value.payment_method).id
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching receipt. Please refresh!')
        })
    }
    const fetchInvoiceMetas = query => {
      if (query && query.includes('-')) return
      let customer = null
      if (receipt.value.customer) { customer = receipt.value.customer.id }

      searchInvoiceLoading.value = true
      store
        .dispatch('invoiceStore/fetchInvoiceMetas', {
          search_text: query, customer_id: customer,
        })
        .then(response => {
          searchInvoiceLoading.value = false
          const { data } = response.data
          invoiceMetaList.value.cachedItems = data
        })
        .catch(error => {
          searchInvoiceLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching invoice metas. Please refresh!')
        })
    }
    const saveCustomer = () => {
      saveCustomerLoading.value = true
      store
        .dispatch('clericalSettingsStore/createCustomer', newCustomer.value)
        .then(response => {
          snackbarService.success(response.data.message)
          isAddNewCustomerDialogOpen.value = false
          saveCustomerLoading.value = false
          saveCustomerForm.value.reset()
          fetchCustomers()

          receipt.value.customer = response.data.data
          receipt.value.customer_id = response.data.data.id
        })
        .catch(error => {
          saveCustomerLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while adding customer. Please refresh!')
        })
    }
    const savePaymentMethod = () => {
      savePaymentMethodLoading.value = true
      store
        .dispatch('knowledgeBaseStore/createPaymentMethod', newPaymentMethod.value)
        .then(response => {
          snackbarService.success(response.data.message)
          addPaymentMethodDialog.value = false
          savePaymentMethodLoading.value = false
          savePaymentMethodForm.value.reset()
          fetchPaymentMethods()

          receipt.value.payment_method = response.data.data.name
          receipt.value.payment_method_id = response.data.data.id
        })
        .catch(error => {
          savePaymentMethodLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while adding payment method. Please refresh!')
        })
    }
    const saveReceipt = () => {
      saveFormLoading.value = true
      const receiptParams = { ...receipt.value }
      store
        .dispatch(`receiptStore/${receiptId ? 'updateReceipt' : 'createReceipt'}`, receiptParams)
        .then(response => {
          snackbarService.success(response.data.message)
          saveFormLoading.value = false
          router.push('/clerical/receipt')
        })
        .catch(error => {
          saveFormLoading.value = false
          saveFormErrors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while saving receipt. Please refresh!')
        })
    }
    const selectInvoice = event => {
      fillInvoiceLoading.value = true
      store
        .dispatch('invoiceStore/fetchInvoice', { id: event, as_form: true })
        .then(response => {
          receipt.value = { ...response.data.data, receipt_date: `${yearNow}-${monthNow}-${dayNow}`, payment_date: `${yearNow}-${monthNow}-${dayNow}` }
          receipt.value.invoice_id = event
          receipt.value.line_items_attributes.forEach(item => delete item.id)
          fillInvoiceLoading.value = false
        })
        .catch(error => {
          fillInvoiceLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching invoices. Please refresh!')
        })
    }
    const fetchActivities = () => {
      store
        .dispatch('receiptStore/fetchActivities', { id: receiptId })
        .then(response => {
          activities.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching activities. Please refresh!')
        })
    }
    const invoiceToForm = () => {
      if (route.value.params.invoice_id) {
        selectInvoice(route.value.params.invoice_id)
      }
    }
    const undoInvoice = () => {
      receipt.value = {
        receipt_date: `${yearNow}-${monthNow}-${dayNow}`,
        payment_date: `${yearNow}-${monthNow}-${dayNow}`,
        customer: {},
        customer_id: null,
        amount_due: 0.00,
        id: null,
        issued_by: '',
        line_items_attributes: [{
          item_id: null,
          category_id: null,
          name: null,
          price: 0.00,
          quantity: 1,
          discount: 0.00,
          amount: 0.00,
        }],
        note: '',
        invoice_id: null,
        total: 0.00,
        payment_amount: null,
      }
    }

    // Watch
    let timer = null
    watch(searchInvoiceText, query => {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
      timer = setTimeout(() => {
        invoiceMetaList.value.cachedItems = []
        fetchInvoiceMetas(query)
      }, 200)
    })

    // Mounted
    onMounted(() => {
      fetchCustomers()
      fetchPaymentMethods()
      invoiceToForm()
      if (receiptId) {
        fetchReceipt(receiptId)
        fetchActivities()
      }
    })

    return {
      // Computed
      receiptDateFormatted,
      paymentDateFormatted,

      // Properties
      receipt,
      organization,
      isIssueDateMenuOpen,
      isPaymentDateMenuOpen,

      searchInvoiceLoading,
      searchInvoiceText,
      invoiceMetas,
      invoiceMetaList,
      fillInvoiceLoading,

      customers,
      newCustomer,
      saveCustomerForm,
      saveCustomerValid,
      saveCustomerLoading,
      isAddNewCustomerDialogOpen,

      paymentMethods,
      newPaymentMethod,
      addPaymentMethodDialog,
      savePaymentMethodForm,
      savePaymentMethodValid,
      savePaymentMethodLoading,
      activities,

      saveFormLoading,
      saveFormErrors,
      customToolBar,

      // Table Handlers
      expanded,
      tableSmHeaders,

      // Validators
      validators: {
        required,
      },

      // Icons
      icons: {
        mdiClose,
        mdiMenuDown,
      },

      // Methods
      fetchPaymentMethods,
      fetchInvoiceMetas,
      saveCustomer,
      savePaymentMethod,
      saveReceipt,
      selectInvoice,
      undoInvoice,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

.clerical-receipt {
  .header-inputs {
    width: 122px;
  }

  .payment-inputs {
    width: 225px;
  }

  .add-products-form {
    .header-spacer {
      // This is according to item actions width
      width: 39px;
    }
    .item-actions {
      @at-root {
        @include theme--child(add-products-form) using ($material) {
          .item-actions {
            border-left: thin solid map-deep-get($material, 'dividers');
          }
        }
      }
    }
  }
}

.append-select {
  position: sticky;
  bottom: 1px;
  display: flex;
  justify-content: center;
}
</style>
